import { ApiClientService } from '../api/api-client.service';
import { Injectable } from '@angular/core';
import {
  IContractConnectedPylonResponse,
  IContractSingle,
  IContractSingleSearchPayload,
  IMakePostpaidContractPayload,
  IRequestParamsWithPagination,
  MARINA_URL_TOKEN,
  PaginatedNormal,
} from '@dm-workspace/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContractsApiService {
  private readonly resourceUrl = `/mms/marinas/${MARINA_URL_TOKEN}/contract`;

  constructor(private apiClientService: ApiClientService) {}

  public fetch(params?: Record<string, string>): Observable<IContractSingle[]> {
    return this.apiClientService.get<IContractSingle[]>(this.resourceUrl, {
      params,
    });
  }
  public markAsEligible(contactId: string): Observable<void> {
    return this.apiClientService.patch<void>(`${this.resourceUrl}/mark-as-eligible/${contactId}`, {});
  }
  public fetchPaginated(
    params?: IRequestParamsWithPagination<IContractSingleSearchPayload>
  ): Observable<PaginatedNormal<IContractSingle>> {
    return this.apiClientService.get<PaginatedNormal<IContractSingle>>(this.resourceUrl, {
      params,
    });
  }

  public fetchById(id: string): Observable<IContractSingle> {
    return this.apiClientService.get<IContractSingle>(`${this.resourceUrl}/${id}`);
  }

  public fetchConnectedBookingPylon(contractId: string): Observable<IContractConnectedPylonResponse> {
    return this.apiClientService.get<IContractConnectedPylonResponse>(
      `${this.resourceUrl}/connected-booking-pylon/${contractId}`
    );
  }

  public handOver(
    [contractId, sensorAlreadyHandled = false]: [string, boolean],
    phoneNumber: string
  ): Observable<void> {
    return this.apiClientService.post(`${this.resourceUrl}/setup-sensors-access/${contractId}`, {
      sensorAlreadyHandled,
      phoneNumber,
    });
  }

  public makePostpaid(contractId: string, params: IMakePostpaidContractPayload): Observable<void> {
    return this.apiClientService.post(`${this.resourceUrl}/setup-postpaid-access/${contractId}`, params);
  }
}
