import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../confirm-modal.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'dm-ui-hand-over-confirm-modal',
  templateUrl: './hand-over-confirm-modal.component.html',
  styleUrls: ['./hand-over-confirm-modal.component.scss'],
})
export class HandOverConfirmModalComponent extends ConfirmModalComponent implements OnInit {
  @Input() customerEmail?: string;
  @Input() customerPhone?: string;
  public phoneForm = new FormControl('', [Validators.pattern(/^\+\d{7,19}$/)]);
  constructor(protected activeModal: NgbActiveModal) {
    super(activeModal);
  }

  ngOnInit(): void {
    if (this.customerPhone) {
      this.phoneForm.setValue(this.customerPhone);
    }
  }

  onSubmit() {
    if (this.phoneForm.invalid) {
      this.phoneForm.markAsTouched();
      this.phoneForm.markAsDirty();
      return;
    }
    this.activeModal.close({ phone: this.phoneForm.value || null });
  }
}
