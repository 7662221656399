<div class="container" [class.removing]="isRemoving">
  <i class="i-check-circle status-icon"></i>

  <p class="title">
    <a class="link" [href]="file.downloadUrl" target="_blank">{{ file.originalFileName }}</a>
  </p>

  <div class="meta">
    <span>{{ file.createdAt | dateFormat }}</span>
    <span>{{ file.fileSize | formatFileSize }}</span>
  </div>

  <button type="button" class="btn-remove" (click)="deleteFile()">
    <i class="icon i-bin"></i>
  </button>
</div>
