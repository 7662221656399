export function getDataLayer() {
  const windowObj = window as unknown as { dataLayer: unknown[] };
  windowObj.dataLayer = windowObj.dataLayer || [];
  return windowObj.dataLayer;
}
const GTM_SESSION_KEY = 'GTM_S_';
export function saveGtmData(data: unknown, sessionId: string) {
  const key: string = GTM_SESSION_KEY + sessionId;
  const savedArray = sessionStorage.getItem(key);
  const layerArray = savedArray ? JSON.parse(savedArray) : [];
  layerArray.push(data);
  sessionStorage.setItem(key, JSON.stringify(layerArray));
}
export function getDataFromSessionStorage(sessionId: string) {
  const key: string = GTM_SESSION_KEY + sessionId;
  const sessionArray = sessionStorage.getItem(key);
  if (sessionArray) {
    const array = JSON.parse(sessionArray) as Array<unknown>;
    array.forEach((item: unknown) => getDataLayer().push(item));
    sessionStorage.removeItem(key);
  }
}
