import { Inject, Injectable } from '@angular/core';
import { ENV } from '@dm-workspace/types';
import { IEnvironment } from '@dm-workspace/environments';

type IFeatureConfig = FeaturesEnvironments[];

export enum Features {
  pylons = 'pylons',
  priceListDelete = 'priceListDelete',
  berthReuse = 'berthReuse',
  orders = 'orders',
  postPaid = 'postPaid',
  sensors = 'sensors',
}

export enum FeaturesEnvironments {
  prod = 'prod',
  stage = 'stage',
  dev = 'dev',
  all = 'all',
}

const FeaturesConfig: Record<Features, IFeatureConfig> = {
  [Features.pylons]: [FeaturesEnvironments.all],
  [Features.berthReuse]: [FeaturesEnvironments.all],
  [Features.priceListDelete]: [FeaturesEnvironments.all],
  [Features.orders]: [FeaturesEnvironments.dev, FeaturesEnvironments.stage],
  [Features.postPaid]: [FeaturesEnvironments.all],
  [Features.sensors]: [FeaturesEnvironments.all],
};

@Injectable({
  providedIn: 'root',
})
export class FeaturesEnabledService {
  private currentEnv: FeaturesEnvironments = this.env.production
    ? FeaturesEnvironments.prod
    : this.env.stage
      ? FeaturesEnvironments.stage
      : FeaturesEnvironments.dev;

  constructor(@Inject(ENV) private env: IEnvironment) {}

  public hasFeatureEnabled(feature: Features): boolean {
    return (
      !FeaturesConfig[feature] ||
      FeaturesConfig[feature].some((value) => value === FeaturesEnvironments.all || value === this.currentEnv)
    );
  }
}
