import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs/operators';

import { AutocompleteService } from '@dm-workspace/data-access';
import {
  AutocompleteTypes,
  IAutocompleteCountyResponse,
  IAutocompleteResponse,
  ListMarinaPylonsShort,
} from '@dm-workspace/types';

@Component({
  selector: 'dm-form-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent implements ControlValueAccessor {
  public model: string;
  public disabled = false;
  public searching = false;

  @Input() id: string;
  @Input() type: AutocompleteTypes = AutocompleteTypes.boatBrand;
  @Input() placeholder: string;
  @Input() editable = false;
  @Input() mapToId = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() inputFormatter?: (value: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() resultFormatter?: (value: any) => string;
  @Input() clearable = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() loadedData: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public blurEvent = new EventEmitter<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public changeOutput = new EventEmitter<any>();
  @Output() public clearOutput = new EventEmitter<void>();

  onChange: (selected: string | null) => void = (selected) => undefined;
  onTouched: () => void = () => undefined;

  constructor(private autocompleteService: AutocompleteService) {}

  search: OperatorFunction<string, readonly IAutocompleteResponse[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) => {
        if (this.type === AutocompleteTypes.pylons && Object.keys(this.loadedData[0]).includes('code')) {
          return of((this.loadedData as ListMarinaPylonsShort[]).filter((item) => item.code.includes(term)));
        }
        return this.autocompleteService.search(term, this.type).pipe(
          catchError(() => {
            return of([]);
          })
        );
      }),
      finalize(() => (this.searching = false))
    );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: string): void {
    this.model = obj;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onModelChange($event?: any): void {
    this.model = $event;
    this.onTouched();
    if (this.type === AutocompleteTypes.county && $event?.details && this.mapToId) {
      return this.onChange(($event as IAutocompleteCountyResponse).details.id);
    } else {
      this.onChange($event);
    }
    if ($event) {
      this.changeOutput.emit($event);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onBlur(event: any): void {
    this.blurEvent.emit(event);
  }

  public clearValue(): void {
    this.model = null;
    this.onChange(null);
    this.clearOutput.emit();
  }
}
