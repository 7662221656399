<div
  *ngIf="!wasClosed"
  [@fromBottomAnimation]
  class="container {{ config.type }}"
  [class.pointer]="config.closeOnClick"
  (click)="closeClick()"
>
  <div class="wrap wrap--default">
    <div class="inner">
      <div class="inner__wrapper {{ customClass }}">
        <i *ngIf="iconClass" class="icon {{ iconClass }}"></i>
        <p *ngIf="title" class="title {{ customClass ? customClass + '__title' : '' }}">{{ title | translate }}</p>
        <p *ngIf="content" class="content {{ customClass ? customClass + '__content' : '' }}" [innerHTML]="content"></p>
        <p *ngIf="contentTmp" class="content"><ng-container [ngTemplateOutlet]="contentTmp" /></p>
      </div>
      <i *ngIf="config.onCloseCallback" class="i-close" (click)="closeByX($event)"></i>
    </div>
  </div>
</div>
