import { ResourceType } from '../backend';

export interface IAutocompleteCountyDetails {
  id: string;
  code: string;
  countryCode: string;
  description: string | null;
  state: IAutocompleteCountyDetailsState;
}

export interface IAutocompleteCountyDetailsState {
  id: string;
  name: string;
}

export type AutocompleteGetStatesParams = {
  search?: string;
  countryCode?: string;
};

export interface IAutocompleteStateDetails {
  id: string;
  name: string;
  code: string;
  countryCode: string;
}

export interface IAutocompleteStateParams {
  countryCode?: string;
  stateId?: string;
  countyId?: string;
}

export interface IAutocompleteResourceDetails {
  id: string;
  name: string;
  pierName: string;
  pierId: string;
}

export type IAutocompleteResourceParams = {
  marinaCode: string;
  pier?: string;
  limit?: number;
  excludeInaccessible?: boolean;
  search?: string;
  types?: ResourceType | ResourceType[];
};

export type IAutocompleteCountyResponse = IAutocompleteResponse<IAutocompleteCountyDetails>;

export type IAutocompleteStateResponse = IAutocompleteResponse<IAutocompleteStateDetails>;

export type IAutocompleteResourceResponse = IAutocompleteResponse<IAutocompleteResourceDetails>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IAutocompleteResponse<T = any> {
  value: string;
  details: T;
}

export enum AutocompleteTypes {
  boatModel = 'boat/dictionaries/model',
  boatBrand = 'boat/dictionaries/brand',
  berths = 'marina/berths',
  ports = 'port',
  county = 'county',
  state = 'state',
  resources = 'marina/resources',
  pylons = 'pylons',
}
