<div
  class="drop"
  [class.hover]="isDraggedFocused"
  dmDrop
  (fileDropped)="addFiles($event)"
  (changeDragStatus)="isDraggedFocused = $event"
  (click)="openFileDialog(input)"
>
  <input
    type="file"
    [multiple]="multiple"
    class="form-hidden-input"
    [accept]="allowedFormatsMime.join(', ')"
    (change)="inputChange($event)"
    #input
  />

  <i class="icon i-image-download"></i>
  <p class="title">
    {{ "FORM_MODULE.FILES.TITLE.0" | translate }}

    <span class="highlight">
      {{ "FORM_MODULE.FILES.TITLE.1" | translate }}
    </span>
  </p>
  <p class="tip" *ngIf="hasTip">
    {{ "FORM_MODULE.FILES.TIP.0" | translate }}: {{ _allowedFormats.join(", ") }}
    {{ "FORM_MODULE.FILES.TIP.1" | translate }} {{ sizeLimitBytes | formatFileSize }}
  </p>
</div>
