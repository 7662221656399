import { Pipe, PipeTransform } from '@angular/core';
import { IBoatAlertResponseDtoSeverityEnum, IBoatAlertResponseDtoStatusEnum } from '@dm-workspace/types';
import { TagColor } from '@dm-workspace/ui';

@Pipe({
  name: 'alertStatusColor',
})
export class AlertStatusColorPipe implements PipeTransform {
  public transform(alert: {
    status: IBoatAlertResponseDtoStatusEnum;
    severity: IBoatAlertResponseDtoSeverityEnum;
  }): TagColor {
    const { status, severity } = alert;

    if (severity === IBoatAlertResponseDtoSeverityEnum.Warning) {
      return 'grey';
    }
    switch (status) {
      case IBoatAlertResponseDtoStatusEnum.Active:
        return 'red';
      case IBoatAlertResponseDtoStatusEnum.InProgress:
        return 'neutral';
      case IBoatAlertResponseDtoStatusEnum.Resolved:
        return 'green';
    }
  }
}
