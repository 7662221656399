/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, forwardRef, inject, input, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateCoreService } from '@dm-workspace/core';
import { COUNTRIES_NEW, CountryCode } from '@dm-workspace/types';

export interface CountriesWithDialCode {
  name?: string;
  dial_code?: string;
  code?: string;
}

@Component({
  selector: 'dm-form-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrl: './phone-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneInputComponent implements ControlValueAccessor {
  userCountry? = input<CountryCode>(null);
  protected readonly COUNTRIES_NEW: CountriesWithDialCode[] = COUNTRIES_NEW;
  protected localeSplited = inject(TranslateCoreService).locale.toUpperCase().split('-');
  protected locale = this.localeSplited[1] || this.localeSplited[0];
  protected selectedCountry?: CountriesWithDialCode = {
    code: this.locale,
    dial_code: this.COUNTRIES_NEW.find((item) => item.code === this.locale)?.dial_code,
    name: this.COUNTRIES_NEW.find((item) => item.code === this.locale)?.name,
  };
  protected phoneNumber: string = '';
  protected isDisabled = signal<boolean>(false);
  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: string | null): void {
    if (value) {
      const phoneValues = value.match(
        /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/
      );
      const dial_code = value[0] === '+' ? '+' + phoneValues[1] : phoneValues[1];
      const phoneNumber = phoneValues[0].split(dial_code)[1];
      this.selectedCountry = this.userCountry()
        ? this.COUNTRIES_NEW.find((item) => item.code === this.userCountry())
        : this.COUNTRIES_NEW.find((item) => item.dial_code === dial_code);
      this.phoneNumber = phoneNumber;
    } else {
      this.phoneNumber = '';
    }
  }

  customSearchFn(term: string, item: CountriesWithDialCode) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 || item.dial_code.toLocaleLowerCase().indexOf(term) > -1;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onDialCodeChange(newDialCode: CountriesWithDialCode): void {
    this.selectedCountry = newDialCode;
    this.updateValue();
  }

  onPhoneNumberChange(newPhoneNumber: string): void {
    this.phoneNumber = newPhoneNumber;
    this.updateValue();
  }

  updateValue(): void {
    if (this.isDisabled()) {
      return;
    }
    this.onChange(`${this.selectedCountry.dial_code} ${this.phoneNumber}`);
    this.onTouched();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }
}
