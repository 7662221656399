import {
  BerthAvailabilityReservationDto,
  BerthBoatAlertStatus,
  BerthMaintenanceStatusInMarina,
  BerthMapResponseDto,
  BerthOccupancyStatusInMarina,
  BerthPylonsStatusInMarina,
  BerthReservationsStatusInMarina,
  BerthStatusInMarina,
  BillingType,
  BoatAbsenceMapResponseDto,
  BoatMovementOperation,
  BoatMovementOperationForStatus,
  BoatSensorInfo,
  ContractType,
  IBoatAlertResponseDtoStatusEnum,
  IBookingCalendarWithIndex,
  IMarinaPylonsResponse,
  IMovement,
  Marina,
  MarinaMapColorStatus,
  PylonsStatusInMarina,
} from '@dm-workspace/types';
import { TagColor } from '@dm-workspace/ui';
import { isWithinInterval } from 'date-fns';

export enum MapViewTypes {
  reservations,
  occupancy,
  sensors,
  pylons,
  statuses,
}
const VAREZZE_TEMP_BOAT = 'SHIPYARD TEMP BOAT';
export const berthStatusToTagColor = (status: BerthStatusInMarina): TagColor => {
  switch (status) {
    case BerthReservationsStatusInMarina.reservedMidTerm:
    case BerthOccupancyStatusInMarina.expectedArrivals:
      return 'orange-light';
    case BerthOccupancyStatusInMarina.expectedDepartures:
      return 'magenta-light';
    case BerthBoatAlertStatus.noAlert:
    case BerthOccupancyStatusInMarina.occupiedWithBooking:
    case BerthReservationsStatusInMarina.allowedOverbooking:
    case PylonsStatusInMarina.billableUsage:
      return 'green-light';
    case BerthOccupancyStatusInMarina.occupiedWithoutBooking:
      return 'yellow-light';
    case BerthBoatAlertStatus.activeAlert:
    case BerthOccupancyStatusInMarina.overOccupied:
    case BerthReservationsStatusInMarina.unallowedOverbooking:
    case PylonsStatusInMarina.nonBillableUsage:
      return 'red-light';
    case BerthBoatAlertStatus.noSubscription:
    case BerthOccupancyStatusInMarina.empty:
    case PylonsStatusInMarina.noUsage:
    case BerthPylonsStatusInMarina.notConnected:
    case BerthReservationsStatusInMarina.available:
      return 'black-light';
    case BerthReservationsStatusInMarina.temporaryAvailableCruise:
    case BerthOccupancyStatusInMarina.temporaryAbsenceCruise:
      return 'light';
    case BerthBoatAlertStatus.eligible:
    case BerthPylonsStatusInMarina.connectedPrepaid:
    case BerthReservationsStatusInMarina.reservedTransient:
      return 'blue-light';
    case BerthReservationsStatusInMarina.reservedLongTerm:
    case BerthPylonsStatusInMarina.connectedPostpaid:
      return 'dark-blue-light';
    case BerthReservationsStatusInMarina.temporaryAvailableDryDock:
    case BerthOccupancyStatusInMarina.temporaryAbsenceDryDock:
      return 'grey-light';
    case BerthMaintenanceStatusInMarina.disabled:
      return 'grey';
  }
};
function splitDates(dateString: string): Date {
  return new Date(dateString.split('T')[0]);
}
function isInGap(gap: BoatAbsenceMapResponseDto, booking: BerthAvailabilityReservationDto) {
  const gapDates = {
    start: splitDates(gap.gapStart || gap.movementOperationDate),
    end: splitDates(gap.gapEnd || gap.expectedReturnDate),
  };
  return (
    isWithinInterval(splitDates(booking.fromDate), gapDates) && isWithinInterval(splitDates(booking.toDate), gapDates)
  );
}

export function getPylonsMapStatus(pylon?: IMarinaPylonsResponse): BerthPylonsStatusInMarina[] {
  if (pylon?.pylonBillingType === BillingType.POSTPAID) {
    return [BerthPylonsStatusInMarina.connectedPostpaid];
  }
  if (pylon?.pylonBillingType === BillingType.PREPAID) {
    return [BerthPylonsStatusInMarina.connectedPrepaid];
  }
  return [BerthPylonsStatusInMarina.notConnected];
}
export function hasUtilities(marina: Marina) {
  return !!marina.additionalProperties?.isPylonsFeatureEnabled;
}
export function getReservationMapStatus(
  berth: BerthMapResponseDto,
  marinaCode: string
): BerthReservationsStatusInMarina[] {
  const alerts = [];
  if (
    marinaCode === 'IV04' &&
    berth.bookings.length === 2 &&
    berth.bookings.some((value) => value.boat?.name === VAREZZE_TEMP_BOAT)
  ) {
    alerts.push(BerthReservationsStatusInMarina.allowedOverbooking);
  } else if (berth.bookings?.length > 1 && (!berth.temporaryAbsence || berth.temporaryAbsence.length === 0)) {
    alerts.push(BerthReservationsStatusInMarina.unallowedOverbooking);
  } else if (berth.bookings?.length > 1) {
    const findWithoutGap = berth.bookings.filter((value) =>
      berth.temporaryAbsence.every((absence) => absence.boatId !== value.boatId)
    );
    alerts.push(
      findWithoutGap.length === 0 ||
        (findWithoutGap.length === 1 && berth.temporaryAbsence.every((value) => isInGap(value, findWithoutGap[0])))
        ? BerthReservationsStatusInMarina.allowedOverbooking
        : BerthReservationsStatusInMarina.unallowedOverbooking
    );
  }
  if (berth.bookings.length === 1) {
    if (berth.bookings[0].contractType === ContractType.TRANSIENT) {
      alerts.push(BerthReservationsStatusInMarina.reservedTransient);
    }
    if (berth.bookings[0].contractType === ContractType.MID_TERM) {
      alerts.push(BerthReservationsStatusInMarina.reservedMidTerm);
    }
    if (berth.bookings[0].contractType === ContractType.LONG_TERM) {
      alerts.push(BerthReservationsStatusInMarina.reservedLongTerm);
    }
    if (
      berth.temporaryAbsence.some(
        (value) => value.operation === BoatMovementOperation.CRUISING && value.allowBerthReuse
      )
    ) {
      alerts.push(BerthReservationsStatusInMarina.temporaryAvailableCruise);
    }
    if (
      berth.temporaryAbsence.some(
        (value) => value.operation === BoatMovementOperation.DRY_DOCK_LIFT && value.allowBerthReuse
      )
    ) {
      alerts.push(BerthReservationsStatusInMarina.temporaryAvailableDryDock);
    }
  }

  if (alerts.length) {
    return alerts;
  }
  return [BerthReservationsStatusInMarina.available];
}
export function getOccupancyMapStatus(berth: BerthMapResponseDto): BerthOccupancyStatusInMarina[] {
  const alerts = [];
  if (berth.occupancy?.length > 1) {
    alerts.push(BerthOccupancyStatusInMarina.overOccupied);
  }
  if (berth.temporaryAbsence.some((value) => value.operation === BoatMovementOperation.CRUISING)) {
    alerts.push(BerthOccupancyStatusInMarina.temporaryAbsenceCruise);
  }
  if (berth.temporaryAbsence.some((value) => value.operation === BoatMovementOperation.DRY_DOCK_LIFT)) {
    alerts.push(BerthOccupancyStatusInMarina.temporaryAbsenceDryDock);
  }
  if (berth.expectedDepartures.length) {
    alerts.push(BerthOccupancyStatusInMarina.expectedDepartures);
  }
  if (berth.expectedArrivals.length) {
    alerts.push(BerthOccupancyStatusInMarina.expectedArrivals);
  }
  if (berth.occupancy?.length === 1) {
    alerts.push(
      berth.bookings.some((value) => value.boat?.id === berth.occupancy[0].boatId)
        ? BerthOccupancyStatusInMarina.occupiedWithBooking
        : BerthOccupancyStatusInMarina.occupiedWithoutBooking
    );
  }
  if (alerts.length) {
    return alerts;
  }
  return [BerthOccupancyStatusInMarina.empty];
}
export function getPylonStatus(pylons: IMarinaPylonsResponse[]): PylonsStatusInMarina {
  if (pylons.some((value) => !value.bookingReferenceId && value.isUsed)) {
    return PylonsStatusInMarina.nonBillableUsage;
  }
  if (pylons.some((value) => !!value.bookingReferenceId && value.isUsed)) {
    return PylonsStatusInMarina.billableUsage;
  }
  return PylonsStatusInMarina.noUsage;
}
export function getAlertMapStatus(berth: BerthMapResponseDto): BerthBoatAlertStatus[] {
  if (berth.occupancy.length === 0) {
    return [BerthBoatAlertStatus.empty];
  }
  if (berth.eligibleContracts.length > 0) {
    return [BerthBoatAlertStatus.eligible];
  }
  if (berth.sensorSubscriptions.length === 0) {
    return [BerthBoatAlertStatus.noSubscription];
  }
  if (
    berth.alerts.length > 0 &&
    berth.alerts.some(
      (value) =>
        value.status === IBoatAlertResponseDtoStatusEnum.Active ||
        value.status === IBoatAlertResponseDtoStatusEnum.InProgress
    )
  ) {
    return [BerthBoatAlertStatus.activeAlert];
  }
  return [BerthBoatAlertStatus.noAlert];
}
export function getBoatsAlertStatuses(berth: BerthMapResponseDto): BerthBoatAlertStatus[] {
  const berthAlertStatuses = [];

  if (berth.occupancy.length === 0) {
    berthAlertStatuses.push(BerthBoatAlertStatus.empty);
  }
  if (
    berth.occupancy.length > 0 &&
    berth.sensorSubscriptions.length !== berth.occupancy.length &&
    berth.occupancy.length !== berth.eligibleContracts.length
  ) {
    berthAlertStatuses.push(BerthBoatAlertStatus.noSubscription);
  }
  if (berth.eligibleContracts.length > 0) {
    berthAlertStatuses.push(BerthBoatAlertStatus.eligible);
  }
  if (berth.sensorSubscriptions.length && !berth.alerts.length) {
    berthAlertStatuses.push(BerthBoatAlertStatus.noAlert);
  }
  if (
    berth.alerts.length > 0 &&
    berth.alerts.some(
      (value) =>
        value.status === IBoatAlertResponseDtoStatusEnum.Active ||
        value.status === IBoatAlertResponseDtoStatusEnum.InProgress
    )
  ) {
    berthAlertStatuses.push(BerthBoatAlertStatus.activeAlert);
  }
  return berthAlertStatuses;
}

// TODO maybe to be deleted
export function getSensorTypeAndBoatName(selectedBerth: BerthMapResponseDto): BoatSensorInfo[] {
  const alerts = Array.from(new Set(selectedBerth?.alerts.map((item) => item.boat.name))).map((boatName) => ({
    name: boatName,
    id: selectedBerth?.alerts.find((alert) => alert.boat.name === boatName).boat.id,
    type: BerthBoatAlertStatus.activeAlert,
  }));

  const sensorSubscriptions = selectedBerth?.sensorSubscriptions
    .filter((sub) => {
      return !selectedBerth.alerts.some((alert) => alert.boat.name === sub.boat.name);
    })
    .map((item) => {
      return {
        name: item.boat.name,
        id: item.boat.id,
        type: BerthBoatAlertStatus.noAlert,
      };
    });

  const eligibleContracts = selectedBerth?.eligibleContracts.map((item) => {
    return {
      name: item.name,
      id: item.id,
      type: BerthBoatAlertStatus.eligible,
    };
  });

  const noSubscription = selectedBerth?.occupancy
    .filter((occ) => {
      return (
        !sensorSubscriptions.some((sub) => sub.id === occ.boat.id) &&
        !eligibleContracts.some((contract) => contract.id === occ.boat.id) &&
        !alerts.some((alert) => alert.id === occ.boat.id)
      );
    })
    .map((item) => {
      return {
        name: item.boat.name,
        id: item.boat.id,
        type: BerthBoatAlertStatus.noSubscription,
      };
    });

  const allSensorItems = [
    ...(sensorSubscriptions || []),
    ...(eligibleContracts || []),
    ...(noSubscription || []),
    ...(alerts || []),
  ];

  return [...new Set(allSensorItems)];
}
export function getBerthStatusColor(booking: IBookingCalendarWithIndex): MarinaMapColorStatus {
  if (booking.overlapping) {
    return MarinaMapColorStatus.red;
  } else if (isTransient(booking.contractType)) {
    return MarinaMapColorStatus.blue;
  } else if (isNotOnMarina(booking.boatStatusInMarina)) {
    return MarinaMapColorStatus.yellow;
  } else if (isOnMarina(booking.boatStatusInMarina)) {
    return MarinaMapColorStatus.green;
  }
  return MarinaMapColorStatus.gray;
}

export function hasMovements(movements: IMovement[]): boolean {
  return movements && movements.length > 0;
}

export function isOnMarina(boatStatusInMarina: BoatMovementOperationForStatus): boolean {
  return [
    BoatMovementOperationForStatus.RETURN,
    BoatMovementOperationForStatus.ARRIVAL,
    BoatMovementOperationForStatus.DRY_DOCK_LAUNCH,
  ].includes(boatStatusInMarina);
}

export function isNotOnMarina(boatStatusInMarina: BoatMovementOperationForStatus, ignoreNull = false): boolean {
  let notOnMarinaStatuses: BoatMovementOperationForStatus[] = [
    BoatMovementOperationForStatus.CRUISING,
    BoatMovementOperationForStatus.DRY_DOCK_LIFT,
    BoatMovementOperationForStatus.DEPARTURE,
  ];

  if (!ignoreNull) {
    notOnMarinaStatuses = [...notOnMarinaStatuses, null];
  }
  return notOnMarinaStatuses.includes(boatStatusInMarina);
}

export function isTransient(contractType: ContractType): boolean {
  return contractType === ContractType.TRANSIENT;
}
