import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteTypes, BoatType, BoatUsage, COUNTRIES, IBoat, IBoatCreatePayload } from '@dm-workspace/types';
import { MmsBoatsApiService } from '@dm-workspace/data-access';
import { ApiValidatorService } from '../../services/api-validator.service';
import { removeEmptyProperties } from '@dm-workspace/utils';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'dm-form-boat-details-form',
  templateUrl: './boat-details-form.component.html',
  styleUrls: ['./boat-details-form.component.scss'],
})
// TODO move component to mms shared module; add prefix mms; use BoatFormService
export class BoatDetailsFormComponent implements OnInit {
  @Input() boat: IBoat;
  @Input() inPlaceEdit: boolean;
  @Input() insideModal: boolean;
  @Input() selectableBoat: boolean;

  @Output() formReady: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Output() boatUpdated = new EventEmitter<IBoat>();
  @Output() updateError = new EventEmitter<HttpErrorResponse>();

  public isPending = false;
  public form = this.buildForm();
  public countries = COUNTRIES;
  public readonly boatTypes = Object.values(BoatType);
  public boatUsage = Object.values(BoatUsage);
  public readonly autocompleteTypes = AutocompleteTypes;

  private lastSearch?: IBoat[];

  constructor(
    private fb: UntypedFormBuilder,
    private boatService: MmsBoatsApiService,
    private apiValidator: ApiValidatorService
  ) {}

  searchBoat: OperatorFunction<string, readonly IBoat[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((search) => (search.length < 2 ? of([]) : this.boatService.fetch({ search }))),
      tap((search: IBoat[]) => {
        this.lastSearch = search;
      })
    );

  ngOnInit(): void {
    this.form.patchValue(this.boat);
    this.formReady.emit(this.form);
  }

  buildForm(): UntypedFormGroup {
    const vssNumber = new UntypedFormControl(null);
    vssNumber.disable();

    return this.fb.group({
      brandId: [null, Validators.required],
      charterLicenceNumber: null,
      draft: [null, [Validators.required, Validators.min(0.1), Validators.max(500)]],
      flag: [null, Validators.required],
      id: null,
      isCharter: false,
      length: [null, [Validators.required, Validators.min(0.1)]],
      maxBeam: [null, [Validators.required, Validators.min(0.1)]],
      name: [null, Validators.required],
      portOfRegistration: [null, Validators.required],
      registrationNumber: [null, Validators.required],
      type: [null, Validators.required],
      usage: [null, Validators.required],
      vssNumber,
    });
  }

  boatResultFormatter = (item: IBoat) => `${item.registrationNumber} (${item.name})`;
  boatInputFormatter = (item: IBoat) => item.registrationNumber;

  onSelectBoat($event: NgbTypeaheadSelectItemEvent<IBoat>): void {
    const regNumberControl = this.form.get('registrationNumber');
    this.form.patchValue($event.item);
    regNumberControl?.patchValue($event.item.registrationNumber);
    regNumberControl?.disable();
    this.boat = $event.item;
  }

  clearBoat(): void {
    this.form.get('registrationNumber')?.setValue('');
    this.form.get('registrationNumber')?.enable();
  }

  onRegistrationNumberBlur($event: FocusEvent): void {
    const value = ($event.target as HTMLInputElement).value;

    if (
      value &&
      this.lastSearch &&
      this.lastSearch.length === 1 &&
      this.lastSearch[0].registrationNumber.toUpperCase() === value.toUpperCase()
    ) {
      this.form.get('registrationNumber')?.setValue(this.lastSearch[0]);
      this.onSelectBoat({
        item: this.lastSearch[0],
        preventDefault: () => true,
      });
      this.lastSearch = undefined;
    }
  }

  public onBoatUsageChange(boatUsage: BoatUsage): void {
    const isCharterControl = this.form.get('isCharter');
    const charterLicenceControl = this.form.get('charterLicenceNumber');

    if (boatUsage === BoatUsage.CHARTER) {
      isCharterControl.patchValue(true);
      charterLicenceControl.addValidators(Validators.required);
    } else {
      isCharterControl.patchValue(false);
      charterLicenceControl.clearValidators();
    }

    charterLicenceControl.updateValueAndValidity();
  }

  public onSubmit(): void {
    if (this.isPending || (this.form && !this.apiValidator.formIsValid(this.form))) {
      return;
    }
    this.isPending = true;

    const formValues = this.form.getRawValue() as IBoatCreatePayload;
    const { registrationNumber, ...restFormValues } = formValues;
    const dto = {
      ...restFormValues,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      registrationNumber: (registrationNumber as any).registrationNumber || registrationNumber,
    } as IBoatCreatePayload;

    const api = this.boat?.id
      ? this.boatService.update(this.boat.id, removeEmptyProperties(dto))
      : this.boatService.create(dto);
    api.pipe(finalize(() => (this.isPending = false))).subscribe({
      next: (boatResponse) => {
        this.boatUpdated.emit(boatResponse);
      },
      error: (res: HttpErrorResponse) => {
        this.updateError.emit(res);
        this.apiValidator.setApiValidationErrors(this.form, res);
      },
    });
  }

  get sectionClassName(): { [key: string]: boolean } {
    return {
      card: !this.inPlaceEdit && !this.insideModal,
      inplace: this.inPlaceEdit,
      'px-xxl': !this.inPlaceEdit && !this.insideModal,
      'py-xl': !this.inPlaceEdit && !this.insideModal,
      'mt-s': !this.inPlaceEdit && !this.insideModal,
    };
  }

  public get rowClass(): string {
    if (this.insideModal) {
      return 'row-cols-4';
    }
    return 'row-cols-6';
  }
}
