import { IMarinaPylonSocketsForUsageResponse, PylonMediaType } from '@dm-workspace/types';

export class PylonUtils {
  static icons: Record<PylonMediaType, string> = {
    [PylonMediaType.ELECTRICITY]: 'i-plug-in',
    [PylonMediaType.WATER]: 'i-drop',
  };
  static validateId(id: string): boolean {
    if (!id) {
      return false;
    }
    return /^\d+$/.test(id);
  }

  static groupPylonSocketsByMediaType(
    pylon: IMarinaPylonSocketsForUsageResponse[]
  ): Record<PylonMediaType, IMarinaPylonSocketsForUsageResponse[]> {
    if (!pylon) {
      return null;
    }
    const uniqueSocketsMediaTypes = [...new Set(pylon.map((socket) => socket.mediaType))];
    const mediaTypesObj: Record<PylonMediaType, IMarinaPylonSocketsForUsageResponse[]> = uniqueSocketsMediaTypes.reduce(
      (acc, mediaType) => {
        acc[mediaType] = [];
        return acc;
      },
      {} as Record<PylonMediaType, IMarinaPylonSocketsForUsageResponse[]>
    );

    return pylon.reduce((acc, socket) => {
      acc[socket.mediaType].push(socket);
      return acc;
    }, mediaTypesObj);
  }
}
