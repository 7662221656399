import { Inject, Injectable } from '@angular/core';
import {
  API_PREFIX,
  ApiPrefix,
  IMarinaPylonsResponse,
  IMarinaPylonsResponseSearch,
  PylonSocketSwitchStateBody,
  MARINA_URL_TOKEN,
  IMarinaPylonSocketsForUsageResponse,
  ListMarinaPylonsShort,
} from '@dm-workspace/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiClientService } from '../api/api-client.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MmsPylonsApiService {
  readonly MMI_PYLONS = `/${this.apiPrefix}/marinas/${MARINA_URL_TOKEN}/pylons`;

  private refreshViewSubject$ = new BehaviorSubject(null);

  get refreshView$(): Observable<null> {
    return this.refreshViewSubject$.asObservable();
  }

  public triggerViewRefresh(): void {
    this.refreshViewSubject$.next(null);
  }

  constructor(
    private apiClient: ApiClientService,
    @Inject(API_PREFIX) private apiPrefix: ApiPrefix
  ) {}

  protected get resourceUrl() {
    switch (this.apiPrefix) {
      case ApiPrefix.customerPanel:
      case ApiPrefix.onlineBooking:
        return `/${this.apiPrefix}/pylons`;
      case ApiPrefix.mmsPanel:
        return this.MMI_PYLONS;
    }
  }

  public getMarinaPylons(): Observable<IMarinaPylonsResponse[]> {
    return this.apiClient.get(`${this.MMI_PYLONS}`);
  }

  public getFilteredMarinaPylons(filters: Partial<IMarinaPylonsResponseSearch>): Observable<IMarinaPylonsResponse[]> {
    return this.getMarinaPylons().pipe(
      map((pylons) => {
        const filtersEntries = Object.entries(filters || {}) as [keyof IMarinaPylonsResponseSearch, string][];
        if (filtersEntries.length === 0) {
          return pylons;
        }
        return pylons.filter((pylon) =>
          filtersEntries.every(([filterKey, filterValue]): boolean => {
            if (filterValue === null || filterValue === undefined) {
              return true;
            }

            switch (filterKey) {
              case 'bookingReferenceId':
              case 'outputName':
              case 'pylonCode':
              case 'pylonName': {
                return pylon[filterKey]?.toLowerCase().includes(filterValue.toLowerCase());
              }
              case 'boatId': {
                return pylon.boat?.id === filterValue;
              }
              case 'resourceId': {
                return pylon.resource?.id.toLowerCase().includes(filterValue.toLowerCase());
              }
              default:
                return pylon[filterKey] === filterValue;
            }
          })
        );
      })
    );
  }

  public switchSocketState(
    pylonCode: string,
    pylonOutput: string,
    params: PylonSocketSwitchStateBody
  ): Observable<void> {
    return this.apiClient.post(`${this.resourceUrl}/${pylonCode}/outputs/${pylonOutput}/switch-state`, params);
  }

  public switchUnplugDetection(
    pylonCode: string,
    pylonOutput: string,
    params: PylonSocketSwitchStateBody
  ): Observable<void> {
    return this.apiClient.post(
      `${this.MMI_PYLONS}/${pylonCode}/outputs/${pylonOutput}/switch-unplug-detection-state`,
      params
    );
  }
  fetchPylonsShort(): Observable<ListMarinaPylonsShort[]> {
    return this.apiClient.get(`${this.MMI_PYLONS}/simple-list`);
  }
  public blockCustomerAccess(bookingHumanReadableId: string): Observable<void> {
    return this.apiClient.post(`${this.MMI_PYLONS}/block-access`, {
      referenceId: bookingHumanReadableId,
    });
  }
  public getSocketsStatusForBooking(
    pylonCode: string,
    bookingHumanReadableId: string
  ): Observable<IMarinaPylonSocketsForUsageResponse[]> {
    return this.apiClient.get(`${this.resourceUrl}/${pylonCode}/for-usage`, {
      params: { referenceId: bookingHumanReadableId },
    });
  }
}
