<div class="modal-header" *ngIf="title">
  <h2 class="title">{{ title | translate }}</h2>
</div>

<div class="modal-body">
  <p [innerHTML]="(body | translate) + (name ? ' <b>' + name + '</b>' : '')"></p>

  <div class="row mt-4">
    <div class="col-6">
      <dm-form-group>
        <label for="customerEmail">{{ "CUSTOMER_EMAIL" | translate }}</label>
        <input id="customerEmail" dmForm type="email" [disabled]="true" [ngModel]="customerEmail" />
      </dm-form-group>
    </div>
    <div class="col-6">
      <form action="" (ngSubmit)="onSubmit()"></form>
      <dm-form-group>
        <label for="customerPhone">{{ "CUSTOMER_PHONE_NUMBER" | translate }}</label>
        <input
          id="customerPhone"
          dmForm
          type="email"
          [formControl]="phoneForm"
          [placeholder]="'CUSTOMER_PHONE_NUMBER' | translate"
        />
        <dm-form-error-message [control]="phoneForm" [pattern]="'FORM_VALIDATOR_ERRORS.PHONE_NUMBER' | translate" />
      </dm-form-group>
    </div>
  </div>

  <div class="visual-line"></div>
  <dm-ui-alert color="warning" class="my-3">
    <dm-ui-info>
      <span class="i-exclamation-mark-circle" icon></span>
      <h4 class="header-12 fw-600">{{ "CLAIM" | translate }}</h4>
      <div
        class="text-sm color-secondary fw-500 mt-1 mb-2"
        [innerHTML]="'HAND_OVER_CONFIRMATION_FOOTNOTES' | translate"
      ></div>
    </dm-ui-info>
  </dm-ui-alert>
</div>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-primary-negative" (click)="activeModal.close(false)">
    {{ cancelBtn | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="onSubmit()">{{ confirmBtn | translate }}</button>
</div>
